<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-7">
				<div class="row">
					<div :class="posFrom == 'rls' ? 'col-md-6' : 'col-md-12'" v-if="!editable && needToPay">
						<div :class="posFrom == 'rls' ? 'bg-success' : 'bg-purple'" class="card border-0 pointer" @click="onSetPayment('cash')">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21 mb-2"></div>
									<div class="d-flex align-items-end flex-wrap" :style="posFrom == 'rls' ? 'color: #ffffff;' : ''">
										<div class="pr-3 mr-auto">
											<i class="fa fa-money font-size-48" />
										</div>
										<div class="font-size-36 mb-n2">Efectivo</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6" v-if="!editable && posFrom == 'rls' && needToPay">
						<div class="card bg-info border-0 pointer" :style="paymentType != 'bank_terminal' ? { opacity: 0.5 } : ''" @click="onSetPayment('bank_terminal')">
							<div class="card-body">
								<div class="text-white font-weight-bold">
									<div class="font-size-21 mb-2"></div>
									<div class="d-flex align-items-end flex-wrap">
										<div class="pr-3 mr-auto">
											<i class="fa fa-credit-card font-size-48" />
										</div>
										<div class="font-size-36 mb-n2">Tarjeta</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12" v-if="!editable && posFrom == 'rls' && needToPay">
						<div class="card bg-warning border-0 pointer mb0" :style="paymentType == 'cash' || paymentType == 'bank_terminal' ? { opacity: 0.5 } : ''" @click="onSetPayment('digital')">
							<div class="card-body">
								<div class="text-white font-weight-bold">
									<div class="font-size-21 mb-2"></div>
									<div class="d-flex align-items-end flex-wrap">
										<div class="pr-3 mr-auto">
											<i class="fa fa-paypal font-size-48" />
										</div>
										<div class="font-size-36 mb-n2">Otra forma de pago</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-12 pt10" v-if="!editable">
						<!-- <p class="text-right mb0 pointer" @click="setCreditSale">Venta a crédito</p> -->
						<div v-if="hasClient">
							<b>Nombre:</b> {{ `${client.client_name} ${client.client_surname}` }} {{ client.client_last_surname | replaceIfLogicalFalse('') }}<br />
							<b>Razón Social:</b> {{ `${client.client_name} ${client.client_surname}` }} {{ client.client_last_surname | replaceIfLogicalFalse('') }}<br />
							<b>RFC:</b> {{ `${client.client_name} ${client.client_surname}` }} {{ client.client_last_surname | replaceIfLogicalFalse('') }}<br />
							<b>Email:</b> {{ client.email | replaceIfLogicalFalse('- No establecido -') }}<br />
							<b>Teléfono principal de contacto:</b> {{ client.primary_phone }} <br />
						</div>
					</div>
					<div v-if="!editable && paymentType != '' && paymentType != 'cash' && paymentType != 'bank_terminal'" class="col-md-12 text-center">
						<div class="row">
							<div class="col-md-4 text-center pointer" @click="onSetPayment('transfer')" :style="paymentType == 'transfer' ? { border: '1px solid #dedede', borderRadius: '10px' } : {}">
								<img src="resources/images/money-transfer.png" height="30" /><br />
								Transferencia
							</div>
							<div class="col-md-4 text-center pointer" @click="onSetPayment('paypal')" :style="paymentType == 'paypal' ? { border: '1px solid #dedede', borderRadius: '10px' } : {}">
								<img src="resources/images/paypal.png" height="30" /><br />
								PayPal
							</div>
							<div class="col-md-4 text-center pointer" @click="onSetPayment('mercadopago')"
								 :style="paymentType == 'mercadopago' ? { border: '1px solid #dedede', borderRadius: '10px' } : {}">
								<img src="resources/images/mercadopago.png" height="30" /><br />
								MercadoPago
							</div>
							<!-- <div class="col-md-3 text-center pointer" @click="onSetPayment('billpocket')" :style="paymentType == 'billpocket' ? { border: '1px solid #dedede', borderRadius: '10px' } : {}">
								<img src="resources/images/billpocket.png" height="30" /><br />
								BillPocket
							</div> -->
						</div>
					</div>
					<div class="col-md-12" v-if="paymentReceived > 0">
						<hr class="hrText" data-content="Cobros" />
					</div>
					<div class="col-md-12">
						<table class="table table-bordered table-condensed table-striped m0 mt10">
							<tbody>
								<tr v-if="numeral(payment.cash).value() > 0">
									<td width="75%"><b>Monto recibido</b></td>
									<td width="30%" class="text-right">
										{{ numeral(payment.cash).format('$0,0.00') }}<i class="fa fa-times ml7 pointer" style="color: red" @click="onClearPayment('cash')" v-if="!editable"></i>
									</td>
								</tr>
								<tr v-if="numeral(payment.bank_terminal).value() > 0">
									<td width="75%">
										<b>Tarjetas bancarias</b>
										<hr />
										<a-collapse accordion>
											<a-collapse-panel v-for="(element, index) in bankTerminalOperations" :key="index" :header="numeral(element.amount).format('$0,0.00')">
												<div class="row">
													<div class="col-md-6">
														<b>Holder</b>
														<a-select v-model="bankTerminalOperations[index].cardHolder" style="width: 100%">
															<a-select-option value="amex"> American Express </a-select-option>
															<a-select-option value="visa"> Visa </a-select-option>
															<a-select-option value="master"> Master Card </a-select-option>
														</a-select>
													</div>
													<div class="col-md-6">
														<b>Tipo</b>
														<a-select v-model="bankTerminalOperations[index].cardType" style="width: 100%">
															<a-select-option value="credit"> Crédito </a-select-option>
															<a-select-option value="debit"> Débito </a-select-option>
															<a-select-option value="credit_3msi"> Crédito 3 MSI </a-select-option>
														</a-select>
													</div>
													<div class="col-md-12 pt5">
														<b>Banco</b>
														<a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="bankTerminalOperations[index].bankingInstitution"
																  style="width: 100%">
															<a-select-option v-for="(element, index) in bankingInstitutionsList" :key="index" :value="element.id"> {{ element.bank_name }}
															</a-select-option>
														</a-select>
														<small @click="getBankingInstitutionsList" class="pointer">Recarga</small>
													</div>
													<div class="col-md-6 pt5">
														<b>Terminación</b>
														<a-input type="text" v-mask="'#####'" v-model="bankTerminalOperations[index].ending" />
													</div>
													<div class="col-md-6 pt5">
														<b>Aprobación</b>
														<a-input type="text" v-model="bankTerminalOperations[index].approval" />
													</div>
												</div>
											</a-collapse-panel>
										</a-collapse>
									</td>
									<td width="30%" class="text-right">
										{{ numeral(payment.bank_terminal).format('$0,0.00') }}<i class="fa fa-times ml7 pointer" style="color: red" @click="onClearPayment('bank_terminal')"
										   v-if="!editable"></i>
									</td>
								</tr>
								<tr v-if="numeral(payment.transfer).value() > 0">
									<td width="75%"><b>Transferencia</b></td>
									<td width="30%" class="text-right">
										{{ numeral(payment.transfer).format('$0,0.00') }}<i class="fa fa-times ml7 pointer" style="color: red" @click="onClearPayment('transfer')" v-if="!editable"></i>
									</td>
								</tr>
								<tr v-if="numeral(payment.paypal).value() > 0">
									<td width="75%"><b>PayPal</b></td>
									<td width="30%" class="text-right">
										{{ numeral(payment.paypal).format('$0,0.00') }}<i class="fa fa-times ml7 pointer" style="color: red" @click="onClearPayment('paypal')" v-if="!editable"></i>
									</td>
								</tr>
								<tr v-if="numeral(payment.mercadopago).value() > 0">
									<td width="75%"><b>MercadoPago</b></td>
									<td width="30%" class="text-right">
										{{ numeral(payment.mercadopago).format('$0,0.00') }}<i class="fa fa-times ml7 pointer" style="color: red" @click="onClearPayment('mercadopago')"
										   v-if="!editable"></i>
									</td>
								</tr>
								<tr v-if="numeral(payment.billpocket).value() > 0">
									<td width="75%"><b>BillPocket</b></td>
									<td width="30%" class="text-right">
										{{ numeral(payment.billpocket).format('$0,0.00') }}<i class="fa fa-times ml7 pointer" style="color: red" @click="onClearPayment('billpocket')" v-if="!editable"></i>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-md-12">
						<hr class="hrDotted" />
					</div>
				</div>
				<div class="row" v-if="paymentType['transfer'] > 0 || paymentType['paypal'] > 0 || paymentType['mercadopago'] > 0 || paymentType['billpocket'] > 0">
					<div class="col-md-12">
						<label>Referencia / Comentarios</label>
						<a-textarea v-model="payment.comments" :disabled="editable" />
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="row">
					<div class="col-md-6 text-left">
						<h3>Total a pagar</h3>
					</div>
					<div class="col-md-6 text-right">
						<h3 class="text-muted">{{ numeral(totalToPay).format('$0,0.00') }}</h3>
					</div>
				</div>
				<div class="row pt10" v-if="advance > 0">
					<div class="col-md-6 text-left">
						<h3 class="m0">Anticipos</h3>
					</div>
					<div class="col-md-6 text-right">
						<h3 class="text-muted">{{ numeral(advance).format('$0,0.00') }}</h3>
					</div>
				</div>
				<div class="row pt10">
					<div class="col-md-6 text-left">
						<h3>Recibido</h3>
					</div>
					<div class="col-md-6 text-right">
						<h3 class="text-muted">{{ numeral(paymentReceived).format('$0,0.00') }}</h3>
					</div>
				</div>
				<div class="row pt10">
					<div class="col-md-6 text-left">
						<h3 v-if="numeral(change).value() < 0">Por recibir</h3>
						<h3 v-else>Cambio</h3>
					</div>
					<div class="col-md-6 text-right">
						<h3 :style="{ color: numeral(change).value() < 0 ? 'red' : 'green' }">{{ numeral(change).format('$0,0.00') }}</h3>
					</div>
				</div>
			</div>
		</div>
	</a-spin>
</template>
<script>
import { mapGetters } from 'vuex'
import { VMoney } from 'v-money'
import numeral from 'numeral'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { mask } from 'vue-the-mask'

export default {
	name: 'paymentComponent',
	directives: {
		money: VMoney,
		mask,
	},
	props: {
		total: {
			type: Number,
			default: 0,
		},
		visible: {
			type: Boolean,
			default: false,
		},
		editable: {
			type: Boolean,
			default: true,
		},
		priceAdjustment: {
			type: Number,
			default: 0,
		},
		advance: {
			type: Number,
			default: 0,
		},
		client: {
			type: Object,
			default: () => {
				return {}
			},
		},
		posFrom: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			paymentIncome: '',
			paymentType: '',
			payment: {
				cash: 0,
				bank_terminal: 0,
				transfer: 0,
				paypal: 0,
				mercadopago: 0,
				billpocket: 0,
				comments: '',
			},
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			bankTerminalOperations: [],
			salesman: '',
		}
	},
	computed: {
		...mapGetters('warehouse', ['spinnerLoaderLabel', 'spinnerLoader']),
		...mapGetters('bankingInstitutions', ['bankingInstitutionsList']),
		...mapGetters('users', ['usersList']),
		change() {
			let result = numeral(this.paymentReceived).value() + numeral(this.advance).value() - numeral(this.totalToPay).value()
			return numeral(result).format('0,0.00')
		},
		totalToPay() {
			return this.total - this.priceAdjustment
		},
		paymentReceived() {
			let { cash, bank_terminal, transfer, paypal, mercadopago, billpocket } = this.payment
			return numeral(cash).value() + numeral(bank_terminal).value() + numeral(transfer).value() + numeral(paypal).value() + numeral(mercadopago).value() + numeral(billpocket).value()
		},
		needToPay() {
			return numeral(this.totalToPay).value() - this.paymentReceived <= 0 ? false : true
		},
		hasClient() {
			return !!utilities.objectValidate(this.client, 'id', false)
		},
	},
	mounted() {
		if (!this.bankingInstitutionsList.length) {
			this.getBankingInstitutionsList()
		}
		if (!this.usersList.length) {
			this.$store.dispatch('users/GET')
		}
	},
	methods: {
		numeral,
		getBankingInstitutionsList() {
			this.$store.dispatch('bankingInstitutions/GET')
		},
		cleanData() {
			this.payment = {
				cash: 0,
				bank_terminal: 0,
				transfer: 0,
				paypal: 0,
				mercadopago: 0,
				billpocket: 0,
				comments: '',
			}
			this.paymentIncome = ''
			this.paymentType = ''
			this.bankTerminalOperations = []
			this.salesman = ''
		},
		onChekOut() {
			if (this.validatePayments()) {
				let payment = Number(numeral(numeral(this.paymentReceived).value() + numeral(this.advance).value()).format('0.00'))
				let totalToPay = Number(numeral(this.totalToPay).format('0.00'))
				if (payment < totalToPay) {
					Swal.fire({
						title: 'Pago',
						html: 'No puede cerrarse la venta sin recibir el pago completo',
						icon: 'warning',
						confirmButtonText: 'Ok',
					})
					return false
				}
				this.onCloseSale()
			}
		},
		onCloseSale() {
			let payload = {
				...this.payment,
				change: numeral(this.change).format('0,0.00'),
				bankTerminalOperations: this.bankTerminalOperations,
				salesman: this.salesman,
			}
			this.$emit('closeSale', payload)
		},
		setTotal(type) {
			//
			if (this.paymentReceived < this.totalToPay) {
				let payment = _.cloneDeep(this.payment)
				payment[type] = numeral(Math.abs(this.change)).format('0,0.00')

				this.payment = _.cloneDeep(payment)
				// this.checkTotal(type)
			}
		},
		validatePayments() {
			let errors = []
			this.bankTerminalOperations.forEach((element) => {
				if (numeral(element.amount).value() < 0) {
					errors.push('- Debes ingresar el monto de la recepción del monto por tarjeta')
				}
				if (element.cardHolder == '') {
					errors.push('- Debes seleccionar el holder de la tarjeta')
				}
				if (element.cardType == '') {
					errors.push('- Debes seleccionar el tipo de la tarjeta')
				}
				if (element.bankingInstitution == '') {
					errors.push('- Debes seleccionar el banco emisor')
				}
				if (element.approval == '') {
					errors.push('- Debes ingresar el número de aprovación de la operación')
				}
			})
			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			return true
		},
		onSetPayment(type) {
			if (this.bankTerminalOperations.length && !this.validatePayments()) {
				return false
			}
			this.paymentType = type

			if (type != 'digital') {
				Swal.fire({
					title: `Ingresa monto`,
					input: 'text',
					showCancelButton: true,
					confirmButtonColor: '#41b883',
					cancelButtonColor: '#f5222e',
					confirmButtonText: 'Continuar',
					cancelButtonText: 'Cancelar',
					reverseButtons: true,
					inputAttributes: {
						autocorrect: 'off',
						autocomplete: 'off',
					},
					inputValidator: (value) => {
						return new Promise((resolve) => {
							if (!isNaN(value)) {
								if (!value || value.length < 0) {
									resolve('Es necesario ingresar monto a recibir')
								}
							} else {
								resolve('Es necesario ingresar monto correcto')
							}
							resolve()
						})
					},
				}).then((result) => {
					if (result.isConfirmed) {
						let amount = numeral(result.value).value()
						let change = Math.abs(numeral(this.change).value())

						// validamos que el pago recibido en digital no exceda el total a pagar
						if (this.paymentType != 'cash' && amount > change) {
							Swal.fire({
								title: 'Pago',
								html: 'No puede recibirse un pago en digital mayor al monto por recibir',
								icon: 'warning',
								confirmButtonText: 'Ok',
							}).then(() => {
								this.onSetPayment(this.paymentType)
							})
							return false
						}
						if (this.paymentType == 'bank_terminal') {
							this.bankTerminalOperations.push({
								ending: '',
								approval: '',
								bankingInstitution: '',
								cardType: '',
								cardHolder: '',
								amount: numeral(amount).value(),
							})
						}
						this.payment[this.paymentType] += amount
						this.paymentType = ''
					}
				})
			}
		},
		onAddIncome() {
			if (numeral(this.paymentIncome).value() > 0) {
				let amount = numeral(this.paymentIncome).value()
				let change = Math.abs(numeral(this.change).value())

				// validamos que el pago recibido en digital no exceda el total a pagar
				if (this.paymentType != 'cash' && amount > change) {
					Swal.fire({
						title: 'Pago',
						html: 'No puede recibirse un pago en digital mayor al monto por recibir',
						icon: 'warning',
						confirmButtonText: 'Ok',
					})
					return false
				}
				this.payment[this.paymentType] += amount
				this.paymentType = ''
			}
		},
		onClearPayment(paymentType) {
			if (paymentType == 'bank_terminal') {
				this.bankTerminalOperations = []
			}
			this.payment[paymentType] = 0
		},
		setCreditSale() {
			this.$emit('setCreditSale')
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
	},
	watch: {
		visible(isVisible) {
			if (!isVisible) {
				this.cleanData()
			}
		},
	},
}
</script>