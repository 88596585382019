<template>
	<div class="row">
		<div class="col-md-6">
			Cantidad:
			<a-input prefix="#" v-mask="'####'" v-model="quickSale.quantity" />
		</div>
		<div class="col-md-6">
			Precio unitario:
			<a-input prefix="$" v-money="money" v-model="quickSale.sale_price" />
		</div>
		<div class="col-md-12 pt10">
			Descripción:
			<a-input v-model="quickSale.label" />
		</div>
	</div>
</template>
<script>
import numeral from 'numeral'
import { VMoney } from 'v-money'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'

export default {
	name: 'quickSaleComponent',
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		money: VMoney,
		mask,
	},
	data() {
		return {
			apiURL: process.env.VUE_APP_API_URL,
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			quickSale: {
				quantity: '',
				label: '',
				sale_price: 0,
			},
		}
	},
	methods: {
		onAddQuickSale() {
			let errors = []
			let sale_price = Number(this.quickSale.sale_price.replace(',', ''))

			if (this.quickSale.quantity == '') {
				errors.push('- Ingresa al menos una unidad para la venta rápida')
			}
			if (this.quickSale.label == '') {
				errors.push('- Ingresa descripción para la venta rápida')
			}
			if (sale_price <= 0) {
				errors.push('- Ingresa precio unitario para la venta rápida')
			}

			if (errors.length) {
				Swal.fire({
					title: 'Ingresos',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}

			this.$emit('addQuickSale', this.quickSale)
		},
		cleanData() {
			this.quickSale = {
				quantity: '',
				label: '',
			}
			this.quickSale.sale_price = numeral(0).format('0,0.00')
		},
	},
	watch: {
		visible() {
			this.cleanData()
		},
	},
}
</script>
